.consent-cookie-box {
  $root: &;
  background: $whiteColor;
  color: $grayDarkColor;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: $indexCookies;
  box-shadow: 0 0 4px 0 rgb(0 0 0 / .5);
  min-height: 25%;
  display: flex;
  justify-content: center;
  flex-direction: column;

  &__inner-box {
    @include container();
  }

  &__content {
    padding: 0;

    @media (min-width: $screen-tablet-portrait) {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }

  &__headline {
    margin: 16px 0 8px;
    font-size: $fontSizeSmall;

    @media (min-width: $screen-tablet-portrait) {
      font-size: $fontSizeXYMedium;
      margin-top: 48px;
    }
  }

  &__text {
    font-size: $fontSizeXSmall;
    line-height: 1.3;
    margin-bottom: 24px;

    @media (min-width: $screen-tablet-portrait) {
      font-size: $fontSizeSmall;
      padding-right: 20px;
      flex: 1 1 auto;
    }

    strong {
      font-weight: $fontWeightSemiBold;
    }

    a,
    a:visited {
      text-decoration: underline;
      white-space: nowrap;
      color: $grayDarkColor;
    }
  }

  &__checkbox-container {
    display: flex;
    margin-bottom: 8px;
    flex-wrap: wrap;

    @media (min-width: $screen-mobile-landscape) {
      justify-content: space-between;
    }

    @media (min-width: $screen-laptop) {
      margin-bottom: 30px;
    }

    &--hide {
      display: none;
    }
  }

  &__checkbox {
    display: block;
    margin-bottom: 16px;
    width: 50%;

    @media (min-width: $screen-mobile-landscape) {
      width: auto;
    }

    @media (min-width: $screen-tablet-portrait) {
      width: 50%;
    }

    @media (min-width: $screen-laptop) {
      width: 46%;
    }

    @media (min-width: $screen-desktop) {
      width: auto;
    }

    .checkbox {
      &__box {
        width: 20px;
        height: 20px;

        &--disabled {
          border-color: $grayColor !important;
          color: $grayColor !important;

          &::before {
            color: $grayColor !important;
          }
        }
      }

      &__label {
        &--disabled {
          color: $grayColor !important;
        }
      }
    }
  }

  &__button-container {
    display: block;
    min-width: 300px;
  }

  &__button-selection,
  &__button-accept-all,
  &__button-reject-all,
  &__button-config {
    border-radius: 1px;
    margin-bottom: 8px;
    border: 0;
    width: 100%;

    @media (min-width: $screen-mobile-landscape) {
      padding: 0 24px;
    }
  }

  &__button-reject-all {
    width: 100%;
    border: 1px solid;

    &::after {
      content: '';
    }
  }

  &__text-container {
    max-height: 40vh;
    overflow-y: auto;
  }

  &__button-selection {
    background: $whiteColor;
    color: $grayMediumColor;
    border: 1px solid $grayMediumColor;
    margin-bottom: 8px;

    @media (min-width: $screen-tablet-xsm) {
      margin-right: 8px;
    }

    body:not(.is_touch) & {
      &:hover {
        background: $consentCookieBoxButtonSecondaryHoverBackgroundColor;
        color: $consentCookieBoxButtonSecondaryHoverTextColor;
        border: $consentCookieBoxButtonSecondaryBorderHover;
      }
    }
  }

  &__button-config {
    text-align: center;
    margin-bottom: 8px;
    color: $grayDarkColor;
    letter-spacing: 0;
    text-decoration: underline $grayDarkColor;
    text-transform: none;
    font-weight: $fontWeightRegular;
    background: none;
    border: 0;
    transition: all .2s;
    font-size: $buttonFontSizeMobile;

    @media (min-width: $screen-tablet-portrait) {
      font-size: $buttonFontSizeDesktop;
    }

    body:not(.is_touch) & {
      &:hover {
        background: none;
        color: $grayDarkColor;
        text-decoration: underline;
      }
    }
  }

  &__button-accept-all {
    color: $consentCookieBoxButtonAcceptColor;
    background-color: $consentCookieBoxButtonAcceptBackgroundColor;
    justify-content: center;

    body:not(.is_touch) & {
      &:hover {
        background: $consentCookieBoxButtonAcceptHoverBackgroundColor;
        color: $consentCookieBoxButtonAcceptHoverTextColor;
        border: $consentCookieBoxButtonAcceptBorderHover;
      }
    }
  }

  &--box {
    #{$root}--configuration {
      @media (min-width: $screen-tablet-portrait) {
        #{$root}__content {
          flex-direction: column;
        }

        #{$root}__col--flex {
          width: 100%;
        }

        .form {
          width: 100%;
          display: flex;
          justify-content: space-between;
        }

        #{$root}__checkbox-container {
          display: flex;
          margin-bottom: 8px;
          flex-wrap: wrap;
          justify-content: space-between;
          width: 54%;
        }

        #{$root}__checkbox {
          align-self: center;
        }
      }
    }
  }

  div[data-placeholder="consent-cookie-box"] & {
    .loader {
      position: absolute;
      right: 0;
      left: 0;
      margin: auto;

      &::before,
      &::after {
        color: $brandPrimaryColor;
      }
    }
  }
}
