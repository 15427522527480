@import "../../../bps/components/consentCookieBox/consentCookieBox.scss";

.consent-cookie-box {
  .button {
    margin-bottom: 16px;
    border-radius: 10px;

    @media (min-width: $screen-desktop) {
      margin-bottom: 8px;
    }
  }

  &__button-config--disabled {
    opacity: .5;
    cursor: not-allowed;

    &:hover {
      background: inherit;
      color: inherit;
      border-color: inherit;
    }

    .loader {
      display: block;
      width: 35px;
      top: 0;
    }
  }

  &__button-accept-all--disabled {
    opacity: .5;
    cursor: not-allowed;
    display: flex;

    &.button:disabled:hover {
      background: $consentCookieBoxButtonSecondaryHoverBackgroundColor !important;
    }

    .loader {
      display: block;
      width: 35px;
    }
  }

  &__button-config {
    text-transform: none;
    text-decoration: underline;
    color: transparentize($grayDarkColor, .3);
  }

  &__text {
    font-size: $fontSizeSmall;
  }

  &__content {
    @media (min-width: $screen-tablet-portrait) {
      padding-bottom: 10px;
    }
  }
}
