.consent-cookie-box-light-box {
  $page-overlay-duration: .3s;
  padding-bottom: 8px;
  top: 50%;
  transform: translate(-50%, -50%);

  .native-app & {
    top: 0;
    height: 100%;
  }

  .lightbox__container {
    margin: 0;
    padding: 0;
    max-height: none;
  }

  &__logo-container {
    text-align: center;
    display: block;
    padding-top: 16px;

    @media (min-width: $screen-tablet-portrait) {
      text-align: left;
      padding-left: 24px;
      padding-top: 16px;
    }

    .logo {
      width: $consentCookieBoxLogoWidth;
    }
  }

  .consent-cookie-box {
    position: static;
    box-shadow: none;

    &__container {
      margin: 0;
      padding: 0;
    }

    &__headline {
      @media (min-width: $screen-tablet-portrait) {
        margin-top: 24px;
        font-size: $baseFontSize;
      }
    }

    &__content {
      @media (min-width: $screen-tablet-portrait) {
        display: block;
      }

      .cookie-box__text {
        overflow-y: auto;
        max-height: 24vh;

        @media (min-width: $screen-tablet-portrait) {
          max-height: none;
        }
      }
    }

    &__checkbox-container {
      .checkbox {
        @media (min-width: $screen-tablet-portrait) {
          width: auto;
        }
      }
    }

    &__button-container {
      padding-top: 8px;
      display: flex;
      flex-direction: column-reverse;

      @media (min-width: $screen-tablet-portrait) {
        padding: 0;
        flex-direction: row-reverse;
        gap: 8px;
      }

      .button {
        @media (min-width: $screen-tablet-portrait) {
          width: auto;
        }
      }
    }
  }

  div[data-placeholder="consent-cookie-box"] & {
    opacity: 1;
    box-shadow: 0 0 0 2000px rgb(0 0 0 / .8);
    transition: box-shadow $page-overlay-duration, opacity $page-overlay-duration linear $page-overlay-duration;

    button {
      &:disabled {
        cursor: not-allowed;

        &:hover {
          background: $buttonDisabledBackgroundColor;
        }
      }
    }

    .loader {
      position: absolute;
      right: 0;
      left: 0;
      margin: auto;

      &::before,
      &::after {
        color: $brandSecondaryColor;
      }
    }
  }
}

// PLACEHOLDER

/* stylelint-disable-next-line */
consent-cookie-box-light-box {
  & > .consent-cookie-box-light-box__logo-container {
    display: none;
  }
}
